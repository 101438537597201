import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import { joinUrlPaths } from '@wix/communities-blog-client-common';
import { BLOG_FEED_PATH } from '@wix/communities-blog-universal/dist/src/constants/app';
import { createLogger } from '../../external/common/controller/helpers';
import getEnvironment from '../../external/common/services/get-environment';

const createController: CreateControllerFn = async ({ controllerConfig }: ControllerParams) => {
  const { setProps, wixCodeApi, appParams } = controllerConfig;
  const { isDebug, isProduction } = getEnvironment(controllerConfig.wixCodeApi);
  const log = createLogger(isDebug, isProduction);
  log('createRssButtonController', { wixCodeApi, appParams });

  return {
    pageReady() {
      log('createRssButtonController.pageReady -> start');
      const baseUrl = controllerConfig.wixCodeApi.location.baseUrl;
      const isSitePublished = Boolean(baseUrl);
      const rssFeedLink = isSitePublished ? joinUrlPaths(baseUrl, [BLOG_FEED_PATH]) : '';
      setProps({ rssFeedLink });
      log('createRssButtonController.pageReady -> done');
    },
  };
};

export default createController;
