import { extend, merge, trimEnd, noop, get, isObject } from 'lodash';
import { retryRequest, urijs } from '@wix/communities-blog-client-common';

export default function createRequest({
  baseUrl = '',
  getInstance = () => null,
  locale = 'en',
  groupId,
  cookie,
  performanceTracker = { trackStart: noop, trackEnd: noop },
  siteRevision,
  trackError = () => {},
  logResponse = () => {},
  petriOvr = '',
  httpClient,
  isSSR,
} = {}) {
  const defaultHeaders = {};
  for (const [key, value] of [
    ['cookie', cookie],
    ['group-ip', groupId],
    ['x-wix-site-revision', siteRevision],
  ]) {
    if (value) {
      defaultHeaders[key] = value;
    }
  }

  const abortableFetch = (request, config) => {
    const signal = httpClient.cancelTokenSource();
    return {
      abort: () => signal.cancel('Fetch was aborted'),
      ready: httpClient.client.request(request, { ...config, cancelToken: signal.token }),
    };
  };

  const request = (path, config = {}) => {
    const parseResponse = config.parseHeaders
      ? (req) => ({ body: req.data, headers: isSSR ? new Headers(req.headers) : req.headers })
      : (req) => req.data;
    const retryCount = config.retry;
    const retryTimeout = 9000;
    const instance = config.instance || getInstance();
    delete config.instance;
    delete config.retry;
    delete config.parseHeaders;

    extend(config, {
      headers: {
        instance,
        Authorization: instance,
        locale,
        ...config.headers,
        ...defaultHeaders,
      },
      credentials: 'same-origin',
    });
    if (petriOvr) {
      config.params = {
        ...config.params,
        petri_ovr: petriOvr,
      };
    }
    if (config.dismissHeaders) {
      config.headers = undefined;
    }
    if (config.dismissParams) {
      config.params = undefined;
    }

    const marker = performanceTracker.trackStart(
      `${new Date().toISOString().slice(11)} ${config.method || 'GET'} ${path}`,
    );
    const url = `${trimEnd(config.baseUrl || config.apiBaseUrl || baseUrl, '/')}${path}`;
    const start = () => abortableFetch(`${url}`, config);
    const parse = (response) =>
      Promise.resolve(response)
        .then(parseResponse)
        .then((r) => {
          performanceTracker.trackEnd(marker);
          return r;
        })
        .then(trackResponse(path, logResponse, trackError))
        .catch((error) => {
          const f = JSON.stringify;
          const status = get(error, 'status');
          const pathWithoutParams = path.split('?')[0];
          if (isObject(error) && !error.stack) {
            const parsedUrlForError = new urijs(url).pathname().split('/').slice(2).join('/');
            const message = `request failed: url=/${parsedUrlForError} status=${status} config=${f(config)} error=${f(
              error,
            )}`;
            error.stack = new Error(message).stack;
          }
          trackError(`request error: path=${pathWithoutParams}, status=${status}, error=${f(error)}`);
          return Promise.reject(error);
        });

    return retryRequest(start, url, retryCount, retryTimeout, httpClient.isCancel).then(parse);
  };

  const defineVerb = (method) => (path, data, config) =>
    request(
      path,
      merge({}, config, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(data),
      }),
    );

  request.post = defineVerb('POST');
  request.put = defineVerb('PUT');
  request.patch = defineVerb('PATCH');

  request.delete = (path, config) =>
    request(
      path,
      extend({}, config, {
        method: 'DELETE',
      }),
    );

  return request;
}

const trackResponse = (path, logResponse) => (response) => {
  logResponse([path, response && response.body ? response.body : response, response.headers || []]);
  return response;
};
