import { get, identity, intersection, map, trim } from 'lodash';

import { resolveId, removeCorruptedEntities, getCategoryIds } from '@wix/communities-blog-client-common';
import { getTranslations } from '../store/translations/translations-selectors';

const DEMO_USER_SITE_MEMBER_IDS = [
  '12345678-1234-1234-1234-123456789123',
  '68c56f39-fe67-4f3d-8ae7-991684463a63', // ru
  'eb619033-dfe7-43df-8bd5-c264da83aeb7', // ja
  '24536dc0-3451-4fa0-aaa1-e826b7828bfb', // es
  '537c64ba-b52b-4643-92fc-cc4e011b7a93', // de
  'f2923da0-3a70-4d26-8d80-b3227e9fe973', // fr
  '8a3e6d16-735e-4618-8be7-964643d23fc7', // pt
  'faf5a4cb-1fd4-4fbc-9e31-77a23ee976eb', // ko
];

const normalizeCategoryIds = (post, blogCategoryIds) =>
  post.categories?.length
    ? post.categories.map(resolveId)
    : discardDeletedCategoryIds(post.categoryIds, blogCategoryIds);

export const getOwnerTitle = (post) => trim(get(post, 'owner.name'));

export const discardDeletedCategoryIds = (postCategoryIds, blogCategoryIds) =>
  intersection(postCategoryIds || [], blogCategoryIds);

const normalizePostPublications = (post) => {
  const { legacyId, likesCount, viewsCount, commentsCount } = post;

  return { ...post, _id: legacyId, likeCount: likesCount, viewCount: viewsCount, totalComments: commentsCount };
};

const getCustomNormalizer = (origin) => {
  switch (origin) {
    case '/v2/posts/publications':
      return normalizePostPublications;
    default:
      return identity;
  }
};

export const normalizePost = ({ state, post, blogCategoryIds, origin }) => {
  const normalize = getCustomNormalizer(origin);
  const normalized = {
    ...normalize(post),
    categoryIds: normalizeCategoryIds(post, blogCategoryIds),
  };

  if (post.owner && DEMO_USER_SITE_MEMBER_IDS.find((id) => id === post.owner.siteMemberId)) {
    const translations = getTranslations(state);
    normalized.owner.name = translations['demo-user.posts-list.owner-name'] || 'Admin';
  }

  if (normalized.content) {
    normalized.content = removeCorruptedEntities(normalized.content);
  }

  const draft = normalized.draft;
  if (!draft) {
    return normalized;
  }

  draft.categoryIds = normalizeCategoryIds(draft, blogCategoryIds);

  if (draft.content) {
    draft.content = removeCorruptedEntities(draft.content);
  }

  if (post.firstPublishedDate && !draft.firstPublishedDate) {
    draft.firstPublishedDate = post.firstPublishedDate;
  }

  return normalized;
};

export const normalizePosts = ({ state, posts, blogCategoryIds, discardCategories, origin }) =>
  map(posts, (post) => normalizePost({ state, post, blogCategoryIds, discardCategories, origin }));

export const normalizePostEntities = (entities, state) =>
  map(entities, (entity) =>
    entity.type === 'post' ? normalizePost({ state, post: entity, blogCategoryIds: getCategoryIds(state) }) : entity,
  );

export const hasUnpublishedChanges = (post) => Boolean(post && post.hasUnpublishedChanges);
