import { get } from 'lodash';
import { ROUTE_404 } from '../../constants/routes';
import { SECTION_CATEGORY, fetchTag } from '@wix/communities-blog-client-common';
import { getIsValidPage, getShowPagination } from '../../../common/selectors/pagination-selectors';
import { isExcludePostContentSupported } from '../../../common/selectors/layout-selectors';
import { fetchTagPosts } from '../../../common/actions/fetch-tag-posts';
import { isSSR, isSite } from '../../../common/store/basic-params/basic-params-selectors';
import { pageOpened } from '../../../common/actions/page-opened';
import { TAGS_PAGE } from '../../../common/services/detect-route';
import { generateTagSEOTags } from '../../../common/services/generate-seo-tags/generate-tag-seo-tags';
import { getPostPageSectionUrl, getSectionUrl } from '../../../common/store/topology/topology-selectors';
import { fetchProfileUrls } from '../../../common/store/profile-urls/profile-urls-actions';
import { getQueryLocale } from '../../../common/selectors/locale-selectors';

export const createTagPageRouter =
  (store, wixCodeApi) =>
  ({ params }, redirect) => {
    let state = store.getState();
    let tag;

    const page = parseInt(get(params, 'page', '1'), 10);
    const tagSlug = get(params, 'tagSlug');
    const language = wixCodeApi.window.multilingual.isEnabled ? getQueryLocale(store.getState()) : null;

    return store
      .dispatch(fetchTag(tagSlug, language))
      .then((_tag) => {
        tag = _tag;
        return store.dispatch(
          fetchTagPosts({
            tagId: _tag.id,
            page,
            excludeContent: isExcludePostContentSupported(store.getState(), SECTION_CATEGORY),
          }),
        );
      })
      .then(({ body: posts }) => {
        state = store.getState();
        const showPagination = getShowPagination(state, SECTION_CATEGORY);
        const isValidPage = getIsValidPage(state, SECTION_CATEGORY);
        if (showPagination && !isValidPage) {
          return redirect(ROUTE_404);
        }
        store.dispatch(fetchProfileUrls());

        if (isSite(state)) {
          const sectionUrl = getSectionUrl(state);
          const tagSEOTags = generateTagSEOTags({
            sectionUrl,
            postPageSectionUrl: getPostPageSectionUrl(state),
            tag,
            state,
            page,
            posts,
            multilingual: wixCodeApi.window.multilingual,
          });
          wixCodeApi.seo.renderSEOTags(tagSEOTags);
        }

        !isSSR(store.getState()) && store.dispatch(pageOpened({ page: TAGS_PAGE }));
      })
      .catch((error) => {
        if (error.status === 404) {
          return redirect(ROUTE_404);
        }
        throw error;
      });
  };
