import { encodeURIComponentIfNeeded } from '../../services/uri';

const preparePathname = (path) => `/${path.map(encodeURIComponentIfNeeded).join('/')}`;

/**
 * There are 2 cases we need to cover:
 *
 * 1. Default one, where vertical component is under a prefix.
 * This way location.path has the prefix inside, so if posts
 * are under /post/*, then path will be ['post', 'post-slug'].
 * We care only about path after component prefix. This is why
 * we need to remove first segment.
 *
 * 2. When site has Custom Router installed, to remove /post
 * and have posts url like wix.com/blog/2020/02/my-post. For
 * this case prefix is set to 2020 and path is ['02', 'my-post'].
 * As this is an advanced slug, we need path to be
 * ['2020', '02', 'my-post'].
 *
 */
export const getPathname = (location, sectionUrl = '') => {
  const lastPathPart = sectionUrl
    .split('/')
    .filter((part) => part)
    .pop();
  const indexOfLastPart = location.path.indexOf(lastPathPart);

  if (indexOfLastPart !== -1) {
    return preparePathname(location.path.slice(indexOfLastPart + 1));
  }

  if (location.prefix) {
    return preparePathname([location.prefix, ...location.path]);
  }

  return '/';
};
