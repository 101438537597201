import { compact } from 'lodash';
import { combineReducers, applyMiddleware } from 'redux';
import createStore from '../../common/services/create-store';
import thunk from 'redux-thunk';
import createRequests from '../../common/services/create-requests';
import commonReducers from '../../common/reducers';
import { appUouBiMiddleware } from '../../common/bi-events/init-middleware';
import { eventMap } from '../bi-events/uou-events';
import tagCloudWidgetReducer from '../aggregated-tag-cloud/reducer';

export function createReduxStore({
  appParams,
  wixCodeApi,
  compId,
  fedopsLogger,
  isEditor,
  platformAPIs,
  bundleName,
  flowAPI,
}) {
  const p = {};

  const { request, platformizedRequest, aggregatorRequest } = createRequests({
    appParams,
    wixCodeApi,
    bundleName,
    getStore: () => p.store,
    flowAPI,
  });

  const biMiddleware = !isEditor && appUouBiMiddleware({ platformAPIs, instanceId: appParams.instanceId, eventMap });

  const middleware = compact([
    thunk.withExtraArgument({
      request,
      platformizedRequest,
      aggregatorRequest,
      wixCodeApi,
      compId,
      appParams,
      fedopsLogger,
    }),
    biMiddleware,
  ]);

  const reducers = combineReducers({
    ...commonReducers,
    tagCloudWidget: tagCloudWidgetReducer,
  });

  const initialState = { experiments: flowAPI.essentials.experiments.all() };

  p.store = createStore(reducers, initialState, applyMiddleware(...middleware));

  return p.store;
}
