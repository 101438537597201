import { resolveId } from '@wix/communities-blog-client-common';

import { SEARCH_CLEAR_RESULTS, SEARCH } from '../actions/search-types';
import { FETCH_SEARCH_SUCCESS, FETCH_SEARCH_FAILURE } from '../actions/fetch-search-types';
import { PIN_POST_SUCCESS } from '../../common/actions/pin-post-types';
import { UNPIN_POST_SUCCESS } from '../../common/actions/unpin-post-types';
import { ENABLE_POST_COMMENTS_SUCCESS } from '../../comments/actions/enable-post-comments-types';
import { DISABLE_POST_COMMENTS_SUCCESS } from '../../comments/actions/disable-post-comments-types';
import { DELETE_POST_SUCCESS } from '../../common/actions/delete-post';

const initialState = {
  posts: [],
};

const post = (state, action) => {
  switch (action.type) {
    case PIN_POST_SUCCESS:
    case UNPIN_POST_SUCCESS:
    case ENABLE_POST_COMMENTS_SUCCESS:
    case DISABLE_POST_COMMENTS_SUCCESS:
      if (resolveId(state) === resolveId(action.payload)) {
        return { ...action.payload, type: 'post' };
      }
      return state;
    default:
      return state;
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH:
      return { ...state, query: action.payload };
    case FETCH_SEARCH_SUCCESS:
      return { ...state, posts: action.payload.posts };
    case FETCH_SEARCH_FAILURE:
    case SEARCH_CLEAR_RESULTS:
      return { ...state, posts: [] };
    case DELETE_POST_SUCCESS:
      return { ...state, posts: state.posts.filter((x) => resolveId(x) !== action.payload) };
    case PIN_POST_SUCCESS:
    case UNPIN_POST_SUCCESS:
    case ENABLE_POST_COMMENTS_SUCCESS:
    case DISABLE_POST_COMMENTS_SUCCESS: {
      let newState = { ...state };
      newState.posts.forEach((item, index) => {
        if (resolveId(item) === resolveId(action.payload)) {
          const currentPost = post(item, action);
          newState = {
            ...newState,
            posts: [...newState.posts.slice(0, index), { ...currentPost }, ...newState.posts.slice(index + 1)],
          };
        }
        if (item.postId === resolveId(action.payload)) {
          const currentPost = post(item, action);
          newState = {
            ...newState,
            posts: [...newState.posts.slice(0, index), { ...currentPost }, ...newState.posts.slice(index + 1)],
          };
        }
      });
      return newState;
    }
    default:
      return state;
  }
};
