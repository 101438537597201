import {
  EXPERIMENT_OOI_IN_EDITOR,
  EXPERIMENT_PROD_OOI_EDITOR,
  EXPERIMENT_DISABLE_NOOP_CONTROLLER,
} from '@wix/communities-blog-experiments';
import getEnvironment from '../services/get-environment';

const initializedControllers = {};

export const editorAdapter = async ({ appData, controllerConfig, flowAPI, createController, createNoopController }) => {
  const { wixCodeApi, appParams, type, compId } = controllerConfig;
  const { isEditor, isPreview } = getEnvironment(wixCodeApi);
  const controllerId = `${type}-${compId}`;

  if (isEditor || isPreview) {
    const experiments = flowAPI.essentials.experiments.all();
    const isIFrameEnabled = getIsIFrameEnabled({ isEditor, isPreview, experiments });
    const isNoopControllerDisabled = getIsNoopControllerDisabled({ experiments });
    const isWorker = getIsWorker();

    if (!isNoopControllerDisabled) {
      const _createNoopController = () => createNoopController({ wixCodeApi, appParams, flowAPI });
      if (isIFrameEnabled && (isWorker || initializedControllers[controllerId])) {
        return _createNoopController();
      }

      if (!isIFrameEnabled && isWorker && initializedControllers[controllerId]) {
        return _createNoopController();
      }
    }
  }

  initializedControllers[controllerId] = true;
  return createController(controllerConfig, undefined, appData, flowAPI);
};

function getIsIFrameEnabled({ isEditor, isPreview, experiments = {} }) {
  const isPreviewIFrame = isPreview && experiments[EXPERIMENT_PROD_OOI_EDITOR] === 'true';
  const isEditorIFrame = isEditor && experiments[EXPERIMENT_OOI_IN_EDITOR] !== 'true';

  return isPreviewIFrame || isEditorIFrame;
}

function getIsNoopControllerDisabled({ experiments = {} }) {
  return experiments[EXPERIMENT_DISABLE_NOOP_CONTROLLER] === 'true';
}

function getIsWorker() {
  return typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope;
}
