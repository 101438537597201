import { createAction, handleAggregatorResponse } from '@wix/communities-blog-client-common';

export const ARCHIVE_WIDGET_SUCCESS = 'archiveWidget/SUCCESS';
export const ARCHIVE_WIDGET_FAILURE = 'archiveWidget/FAILURE';

export const archiveWidgetSuccess = createAction(ARCHIVE_WIDGET_SUCCESS);
export const archiveWidgetFailure = createAction(ARCHIVE_WIDGET_FAILURE);

const createArchiveMenuWidget =
  (action) =>
  (...args) =>
  (dispatch) => {
    return dispatch(action(...args))
      .then((archiveData) => dispatch(archiveWidgetSuccess(archiveData)))
      .catch(() => dispatch(archiveWidgetFailure()));
  };

export const handleArchiveWidgetResponse = createArchiveMenuWidget(handleAggregatorResponse);
