import { getOuterUrl, urijs } from '@wix/communities-blog-client-common';

const getPostUrl = (topology, paths) =>
  urijs
    .joinPaths(urijs(topology.baseUrl).pathname(), topology.postPagePath, ...paths)
    .absoluteTo(topology.baseUrl)
    .toString();

export const getCreatePostUrl = (topology) => getOuterUrl('/create-post', topology.postPageSectionUrl);
export const getEditPostUrl = (topology, postId) => getOuterUrl(`/${postId}/edit`, topology.postPageSectionUrl);

export default getPostUrl;
