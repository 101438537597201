import { values, includes, trimStart } from 'lodash';
import { getTopology } from '../store/topology/topology-selectors';
import { INSTANCE_ID_WIX } from '@wix/communities-universal/dist/src/constants/wix-blogs';
import { isSite } from '../store/basic-params/basic-params-selectors';
import { BLOG_APP_ID, SEARCH_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import {
  SECTION_BLOG_PAGE,
  SECTION_BLOG_POST_PAGE,
  SECTION_SEARCH_RESULTS,
  isExperimentEnabled,
  getCustomPostUrl,
  encodeQuery,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_PROD_OOI_EDITOR, EXPERIMENT_USE_SITE_SEARCH } from '@wix/communities-blog-experiments';
import Wix from '../services/wix-sdk-polyfill';

export const navigateInPreview =
  (link, sectionId) =>
  (dispatch, getState, { wixCodeApi, compId }) => {
    const { baseUrl, sectionUrl } = getTopology(getState());
    const path = link.replace(baseUrl, '');

    if (wixCodeApi.location.navigateToSection) {
      const sectionPath = getSectionPath({ getState, sectionId });
      const strippedPath = path.replace(sectionPath, '');

      return wixCodeApi.location.navigateToSection({
        compId,
        sectionId,
        noTransition: true,
        state: trimStart(strippedPath, '/'),
      });
    }

    if (path === '' || path === '/') {
      const sectionPath = sectionUrl.replace(baseUrl, '');
      return wixCodeApi.location.to(sectionPath);
    }

    return wixCodeApi.location.to(path);
  };

export const navigateToSectionInPreview =
  (path, sectionId) =>
  (_dispatch, getState, { wixCodeApi, compId }) => {
    path = trimStart(path, '/');

    if (isExperimentEnabled(getState(), EXPERIMENT_PROD_OOI_EDITOR)) {
      return Wix.Utils.navigateToSection(
        {
          appDefinitionId: BLOG_APP_ID,
          sectionId,
          shouldRefreshIframe: false,
        },
        path,
      );
    }

    return wixCodeApi.location.navigateToSection({
      compId,
      sectionId,
      noTransition: true,
      state: path,
    });
  };

export const navigateToSearch =
  (path) =>
  (dispatch, getState, { wixCodeApi }) => {
    const isSiteSearchInstalled = wixCodeApi.site.isAppSectionInstalled({ appDefinitionId: SEARCH_APP_ID, sectionId: 'search_results' });
    if (isExperimentEnabled(getState(), EXPERIMENT_USE_SITE_SEARCH) && isSiteSearchInstalled) {
      navigate({
        wixCodeApi,
        getState,
        sectionPath: `/${SECTION_SEARCH_RESULTS}`,
        path: path ? `/q-${encodeQuery(path)}/qc-blogs/` : '',
        appDefinitionId: SEARCH_APP_ID
      });
    } else {
      navigate({ wixCodeApi, getState, path: path ? `/search/${encodeQuery(path)}` : '/search', sectionId: SECTION_BLOG_PAGE });
    }
  };

export const navigateWithinBlog =
  (path, options) =>
  (dispatch, getState, { wixCodeApi }) =>
    navigate({ wixCodeApi, getState, sectionId: SECTION_BLOG_PAGE, path, options });

export const navigateWithinPostPage =
  (path) =>
  (dispatch, getState, { wixCodeApi }) =>
    navigate({ wixCodeApi, getState, sectionId: SECTION_BLOG_POST_PAGE, path });

export const navigateToCustomPostUrl =
  (customUrl, fallbackPath) =>
  (dispatch, getState, { wixCodeApi }) => {
    if (customUrl) {
      const { baseUrl } = getTopology(getState());
      return wixCodeApi.location.to(customUrl.replace(baseUrl, ''));
    }

    return dispatch(navigateWithinPostPage(fallbackPath));
  };

export const navigateProGalleryWithinPostPage =
  (postSlug) =>
  (dispatch, getState, { wixCodeApi, appParams }) => {
    const state = getState();
    const { baseUrl, postPageSectionUrl: sectionUrl } = getTopology(state);
    const sectionPath = sectionUrl.replace(baseUrl, '');

    const customPostUrl = getCustomPostUrl(state, postSlug).replace(baseUrl, '');
    const path = customPostUrl || `${sectionPath}/${postSlug}`;

    if (!isSite(state)) {
      if (isExperimentEnabled(state, EXPERIMENT_PROD_OOI_EDITOR)) {
        return Wix.Utils.navigateToSection(
          {
            appDefinitionId: BLOG_APP_ID,
            sectionId: SECTION_BLOG_POST_PAGE,
            shouldRefreshIframe: false,
          },
          postSlug,
        );
      } else {
        return wixCodeApi.location.to(path);
      }
    }

    // Temporary hack for marketing sites, because wix code navigation doesn't work properly
    if (includes(values(INSTANCE_ID_WIX), appParams.instanceId)) {
      return wixCodeApi.location.to(customPostUrl || `${sectionUrl}/${postSlug}`);
    }

    return wixCodeApi.location.to(path);
  };

function navigate({ wixCodeApi, getState, sectionId, path, options, appDefinitionId = BLOG_APP_ID, sectionPath }) {
  if (!isSite(getState()) && isExperimentEnabled(getState(), EXPERIMENT_PROD_OOI_EDITOR)) {
    return Wix.Utils.navigateToSection(
      {
        appDefinitionId,
        sectionId,
        shouldRefreshIframe: false,
      },
      path,
    );
  }

  sectionPath = sectionPath || getSectionPath({ getState, sectionId });
  return wixCodeApi.location.to(`${sectionPath}${path}`, options);
}

function getSectionPath({ getState, sectionId }) {
  const { baseUrl, sectionUrl, postPageSectionUrl } = getTopology(getState());
  const _sectionUrl = sectionId === SECTION_BLOG_PAGE ? sectionUrl : postPageSectionUrl;

  return _sectionUrl.replace(baseUrl, '');
}
