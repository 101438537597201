import { ARCHIVE_PARAMS } from '@wix/communities-blog-client-common';

import { getQueryLocale } from '../../common/selectors/locale-selectors';
import getEnvironment from '../../common/services/get-environment';
import { handleArchiveWidgetResponse } from '../aggregated-archive/actions';
import { getMonthsDisplayLimit, getShowNewestPostsFirst } from '../selectors/archive-selectors';

export const fetchArchiveWidgetInitialData =
  ({ useICU } = {}) =>
  async (dispatch, getState, { aggregatorRequest, wixCodeApi }) => {
    const state = getState();
    const langQuery = getQueryLocale(state);
    const language = langQuery ? `&language=${langQuery}` : '';
    const showNewestPostsFirst = getShowNewestPostsFirst(state);
    const timeZone = wixCodeApi.site.timezone ? `&timeZone=${wixCodeApi.site.timezone}` : '';
    const icu = useICU ? '&icu=true' : '';

    const { isEditor, isPreview } = getEnvironment(wixCodeApi);
    const monthsDisplayLimit =
      isEditor || isPreview ? ARCHIVE_PARAMS.monthsDisplayLimit.maxValue : getMonthsDisplayLimit(state);

    const path = `/v1/archive-widget/render-model?months=${monthsDisplayLimit}&showNewestPostsFirst=${showNewestPostsFirst}${timeZone}${language}${icu}`;
    const { archive } = await aggregatorRequest(path, { throwOnInvalidJson: true });

    await dispatch(handleArchiveWidgetResponse(archive));
  };
